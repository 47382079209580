/** @jsx jsx */
import { graphql } from "gatsby";
import { Box, Container, Heading, jsx } from "theme-ui";
import ContentWithImage from "../../components/ContentWithImage";
import HexagonList from "../../components/HexagonList";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

const RoofCleaning = ({ data }) => {
  return (
    <Layout
      contactBackground={data.ContactBackground.childImageSharp.gatsbyImageData}
      headerBannerText="roof cleaning"
      headerBgImage={data.HeroImage.childImageSharp.gatsbyImageData}
      headerFancyBanner={false}
      headerLinkButtonEnabled={true}
    >
      <Seo title="Roof Cleaning" />

      <Box as="article" sx={{ my: [8, null, 11] }}>
        <Container>
          <ContentWithImage
            imageAlt="Roof covered in leaves"
            imageURL={data.MaintenanceServices.childImageSharp.gatsbyImageData}
            titleText="Cleaning Roofs in the Greater Phoenix Area"
          >
            <p>
              Roofing cleaning is a standard part of care and maintenance. Most
              cleaning in Arizona is a simple process: removing debris and
              excess dirt from the roof that might interfere with proper
              drainage. Tile roofs in particular are at risk of blockages and
              damming that can be caused by anything from dust storms to palm
              trees shedding smaller branches. Debris builds up overtime which
              can cause bigger problems when bigger weather systems blow through
              and rain comes down hard - especially during the monsoon season.
            </p>

            <Heading as="h2" variant="styles.h3" sx={{ mt: 6 }}>
              Leave Roof Cleaning to the Professionals
            </Heading>

            <p>
              Roofs are inherently one of the most dangerous things to maintain
              for a homeowner, as they require the use of a ladder. With over
              164,000 ladder-related emergency room visits per year, it is less
              than ideal to try and clean your roof on your own. Additionally,
              roofing professionals will know where to look for potential areas
              of buildup and be able to fully inspect trouble spots.
            </p>

            <Heading as="h2" variant="styles.h3" sx={{ mt: 6 }}>
              Choosing Roof Cleaning Companies
            </Heading>

            <p>
              It’s important to use a state-registered contractor for any
              cleanings and inspections. Companies should have a current license
              and have a respectable reputation in the community. Beware of
              storm-chasers and individuals who do not have credentials of some
              sort. Scams are persistent, and many people tend to operate on
              handshake agreements that leave them open to being defrauded.
              Ensure you are getting your roof cleaned by a licensed contractor
              like Legacy Repair.
            </p>
          </ContentWithImage>
        </Container>
      </Box>

      <HexagonList
        bannerText="roof repair made easy."
        linkText="Get Started"
        linkURL="/contact"
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    HeroImage: file(relativePath: { eq: "roof-cleaning-hero.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    RoofTopsIcon: file(relativePath: { eq: "roof-tops.svg" }) {
      id
      publicURL
    }
    ContactBackground: file(relativePath: { eq: "footer-bg.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    MaintenanceServices: file(
      relativePath: { eq: "roof-covered-in-leaves.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default RoofCleaning;
